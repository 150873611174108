


































































































































































































































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { entrevista } from "@/shared/dtos/entrevista";
import { UtilsPublicResources } from "@/utils/utils-public-resources";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
@Component({
  components: {
    CompHeader: () => import("./CompHeaderLabel.vue"),
    DesingnCard: () => import("@/components/Cards/DesingnCard.vue"),
    ChartLine: () => import("@/components/Charts/ChartLine.vue"),
  },
})
export default class ComKcalDieta extends Vue {
  @PropSync("valor", { type: Number }) syncedvalor!: number;
  @PropSync("objentrevista") entrevista!: entrevista;
  @Prop() index!: number;
  public segun_objetivo_min_ant: number = 0;
  public unidadmedida: string = "Kcal";
  public visible_dash: boolean = false;
  public created() {
    this.$emit("validado", { index: this.index, valido: true });
    dieta_visualizacionModule.getDashHistKcalDieta(
      this.entrevista.numero_paciente
    );
  }
  public get min(): number {
    let num = this.ValorDeKcal.bajar_peso - 500;
    if (num <= 0) {
      return 500;
    }
    //obiamos lo otro
    return num;
  }
  public get max(): number {
    let num = this.ValorDeKcal.subir_peso + 700;
    if (num <= 0) {
      return 1500;
    }
    //obiamos lo otro
    return num;
  }
  public get dash_hist_kcal() {
    return dieta_visualizacionModule.dash_historico;
  }

  public get getvalue() {
    return UtilsString.ValueOf(this.syncedvalor);
  }
  public get ValorDeKcal() {
    let meta: number = 0; //metabolismo basal
    let actv: number = 0; //Actividad fisica
    let mantener_peso: number = 0; //mantener_peso
    let subir_peso: number = 0; //subir_peso
    let bajar_peso: number = 0; //bajar_peso
    let segun_objetivo_min: number = 0; //segun_objetivo_min
    let segun_objetivo_max: number = 0; //segun_objetivo_min
    switch (this.entrevista.sexo) {
      case "Hombre":
        let calcpeso = 13.74 * this.entrevista.peso;
        let calcaltura = 5.03 * this.entrevista.altura * 100;
        let calcedad =
          6.75 *
          (new Date().getFullYear() -
            new Date(this.entrevista.fecha_nacimiento).getFullYear());
        meta = 66.5 + calcpeso + calcaltura - calcedad;

        if (this.entrevista.actividad_fisica == "Muy ligera") {
          actv = 1.3;
        } else if (this.entrevista.actividad_fisica == "Ligera") {
          actv = 1.6;
        } else if (this.entrevista.actividad_fisica == "Moderada") {
          actv = 1.7;
        } else if (this.entrevista.actividad_fisica == "Intensa") {
          actv = 2.1;
        } else if (this.entrevista.actividad_fisica == "Muy intensa") {
          actv = 2.4;
        }
        meta = meta - 50; //optimizar
        mantener_peso = meta * actv;
        subir_peso = meta * actv * 1.1;
        if (mantener_peso < 2000) {
          let calc = meta * 0.25;
          bajar_peso = meta - calc;
        } else {
          bajar_peso = meta - 500;
        }
        switch (this.entrevista.id_objetivo) {
          case 132:
          case 133:
          case 134:
            segun_objetivo_min = subir_peso;
            segun_objetivo_max = subir_peso + 300;
            break;
          case 135:
          case 136:
            segun_objetivo_min = mantener_peso;
            segun_objetivo_max = mantener_peso + 300;
            break;
          case 137:
          case 138:
          case 139:
            segun_objetivo_min = bajar_peso;
            segun_objetivo_max = bajar_peso + 300;
            break;
          default:
            segun_objetivo_min = mantener_peso;
            segun_objetivo_max = mantener_peso + 300;
        }
        break;
      case "Mujer":
        let calcpesom = 13.74 * this.entrevista.peso;
        let calcalturam = 5.03 * this.entrevista.altura * 100;
        let calcedadm =
          6.75 *
          (new Date().getFullYear() -
            new Date(this.entrevista.fecha_nacimiento).getFullYear());
        meta = 66.5 + calcpesom + calcalturam - calcedadm;

        if (this.entrevista.actividad_fisica == "Muy ligera") {
          actv = 1.3;
        } else if (this.entrevista.actividad_fisica == "Ligera") {
          actv = 1.5;
        } else if (this.entrevista.actividad_fisica == "Moderada") {
          actv = 1.6;
        } else if (this.entrevista.actividad_fisica == "Intensa") {
          actv = 1.9;
        } else if (this.entrevista.actividad_fisica == "Muy intensa") {
          actv = 2.2;
        }
        meta = meta - 50; //optimizar
        mantener_peso = meta * actv;
        subir_peso = meta * actv * 1.1;
        if (mantener_peso < 2000) {
          let calc = meta * 0.25;
          bajar_peso = meta - calc;
        } else {
          bajar_peso = meta - 500;
        }
        switch (this.entrevista.id_objetivo) {
          case 132:
          case 133:
          case 134:
            segun_objetivo_min = subir_peso;
            segun_objetivo_max = subir_peso + 300;
            break;
          case 135:
          case 136:
            segun_objetivo_min = mantener_peso;
            segun_objetivo_max = mantener_peso + 300;
            break;
          case 137:
          case 138:
          case 139:
            segun_objetivo_min = bajar_peso;
            segun_objetivo_max = bajar_peso + 300;
            break;
          default:
            segun_objetivo_min = mantener_peso;
            segun_objetivo_max = mantener_peso + 300;
        }
        break;
    }
    if (this.segun_objetivo_min_ant !== segun_objetivo_min) {
      this.syncedvalor = Math.ceil(segun_objetivo_min);
      this.segun_objetivo_min_ant = segun_objetivo_min;
    }

    let arraynumber: number[] = [];
    arraynumber.push(Math.ceil(meta));
    arraynumber.push(Math.ceil(mantener_peso));
    arraynumber.push(Math.ceil(subir_peso));
    arraynumber.push(Math.ceil(bajar_peso));
    arraynumber.push(Math.ceil(segun_objetivo_min));
    arraynumber.push(Math.ceil(segun_objetivo_max));
    arraynumber.sort();
    let numero_mas_aproximado = arraynumber.reduce((prev, curr) => {
      return Math.abs(curr - this.syncedvalor) <
        Math.abs(prev - this.syncedvalor)
        ? curr
        : prev;
    });
    let color: string = "";
    let image = "";

    switch (numero_mas_aproximado) {
      case Math.ceil(segun_objetivo_min):
        color = "#fbab7e";
        image = this.getimagen("fruta.svg");
        break;
      case Math.ceil(segun_objetivo_max):
        color = "#6570DF";
        image = this.getimagen("lunch.png");
        break;
      case Math.ceil(meta):
        color = "#FA8184";
        image = this.getimagen("breakfast.png");
        break;
      case Math.ceil(mantener_peso):
        color = "#FF5489";
        image = this.getimagen("snack.png");
        break;
      case Math.ceil(subir_peso):
        color = "#FCCE98";
        image = this.getimagen("dinner.png");
        break;
      case Math.ceil(bajar_peso):
        color = "#768BE6";
        image = this.getimagen("glass.png");
        break;
    }
    return {
      color: color,
      imagen: image,
      metabolismo: Math.ceil(meta),
      mantener_peso: Math.ceil(mantener_peso),
      subir_peso: Math.ceil(subir_peso),
      bajar_peso: Math.ceil(bajar_peso),
      segun_objetivo_min: Math.ceil(segun_objetivo_min),
      segun_objetivo_max: Math.ceil(segun_objetivo_max),
    };
  }
  public getimagen(imagen: string) {
    return UtilsPublicResources.getImageFromImages(imagen);
  }
}
